 .app-container {
   position: relative;
   display: flex;
   align-items: flex-start;
   flex-wrap: nowrap !important;
 }

 .app-content {
   position: relative;
   flex-grow: 1;
   min-width: 44em;
   margin-left: 350px;
   padding-top: 1em;
   transition: transform 0.3s ease;
 }

 .app-content.shifted,
 .app-content.full-width {
   padding-left: 1.4em;
 }

 .app-content.full-width {
  margin-left: 2em;
}

 .sidebar-container,
 .app-content,
 .row {
   width: auto !important;
 }

 .sidebar {
   width: 350px;
   padding-right: 1.4em;
   padding-left: 1em;
   visibility: visible;
   opacity: 1;
   transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
   overflow-y: auto; 
    height: calc(100% - 64px);; 
   background-color: white;
 }

 .sidebar-container {
  position: fixed;
  top: 64px;
  height: 100vh;
  display: flex;
 z-index: 500;
}
 .sidebar.closed {
   height: 100vh;
   opacity: 0;
   visibility: hidden;
   width: 0;
   transform: translateX(-100%);
 }

 .separator-line {
   position: absolute;
   top: 0;
   left: 350px;
   width: 2px;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
   background-color: #dee2e6;
   transition: transform 0.3s ease-in-out;
   z-index: 500;
 }

 button:focus {
   outline: none !important;
 }

 .sidebar.closed+.separator-line {
   left: 1.4em;
 }

 .sidebar.closed+.app-content {
   transform: translateX(350px);
 }

 .toggle-btn:active,
 .sidebar-container:hover .separator-line{
   filter: brightness(0.9);
 }

 .sidebar:hover+.separator-line:hover .toggle-btn:focus-visible {
   outline: none;
 }

.sidebar-container:hover .toggle-btn {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

 .sidebar.open+.separator-line .toggle-btn,
 .sidebar.closed+.separator-line .toggle-btn {
   transform: translate(-50%, -50%);
 }

 .toggle-btn {
  position: absolute;
   top: 50%;
   left: 50%;
   width: 30px;
   height: 30px;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 50%;
   border: none;
   color: white;
   cursor: pointer;
   opacity: 0;
   background-color: var(--primary-color);
 }

 @media (max-width: 1090px) {
   .sidebar-container {
     position: fixed;
     z-index: 1000;
   }

   .separator-line {
     z-index: 1000;
   }

   .app-content.overlay::before {
     content: "";
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100vh;
     background: rgba(0, 0, 0, 0.5);
     z-index: 9;
     transition: 0.1s ease-in-out;
   }

 }